import "./CTA.scss";
import { Link } from "react-router-dom";

export default function CTA() {
    return (
        <div className="cta" data-aos="zoom-in" data-aos-duration="750">
            <div className="cta-title-container">
                <h1 className="cta-title">
                    More Speed
                    <br />
                    More Precision
                    <br />
                    More Control
                </h1>
                <h2 className="cta-subtitle">Try it now and experience AI-powered dental design.</h2>
            </div>
            <Link className="btn-start-now" to="/contact" onClick={() => window.scrollTo(0, 0)}>
                Start Now
            </Link>
        </div>
    );
}
