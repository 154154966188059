import "./Benefits.scss";

export default function Benefits() {
    return (
        <div className="benefits">
            <div className="img-container">
                <img src={require("../../../../assets/img/dentist/home/benefit_img.webp")} alt="Software Appearance" />
            </div>
            <div className="whole-benefits-content">
                <div className="benefit-box" data-aos="fade-down" data-aos-duration="750">
                    <img src={require("../../../../assets/img/dentist/home/icon_benefit_easy.webp")} alt="Icon 1" />
                    <div className="benefit-text-container">
                        <p className="benefit-title">Easy to Use</p>
                        <p className="benefit-description">Upload, design, and approve with just a few clicks.</p>
                    </div>
                </div>
                <div className="benefit-box" data-aos="fade-down" data-aos-duration="750" data-aos-delay="100">
                    <img src={require("../../../../assets/img/dentist/home/icon_benefit_flex.webp")} alt="Icon 2" />

                    <div className="benefit-text-container">
                        <p className="benefit-title">Flexible Access</p>
                        <p className="benefit-description">Access your AI-powered workflow from any device.</p>
                    </div>
                </div>
                <div className="benefit-box" data-aos="fade-down" data-aos-duration="750" data-aos-delay="200">
                    <img
                        src={require("../../../../assets/img/dentist/home/icon_benefit_efficient.webp")}
                        alt="Icon 3"
                    />
                    <div className="benefit-text-container">
                        <p className="benefit-title">Cost-Effective & Efficient</p>
                        <p className="benefit-description">Reduce manual work and scale production effortlessly.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
