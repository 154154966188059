import "./Steps.scss";

export default function Steps() {
    return (
        <div className="whole-step-container">
            <div className="title-container">
                <p className="text-title">
                    Simplify Dental Design <br /> with Dentscape AI
                </p>
            </div>
            <div className="detailed-steps-container">
                <div className="step-box" data-aos="fade-up" data-aos-duration="750">
                    <img
                        src={require("../../../../assets/img/dentist/home/step_upload_icon.webp")}
                        alt="step1"
                        className="step-image"
                    />
                    <p className="text-step-index">Step 1</p>
                    <p className="text-step-name">Bulk Upload & AI Reads Prescriptions</p>
                    <p className="text-step-description">Upload up to 100 cases—AI extracts prescriptions instantly.</p>
                </div>
                <div className="step-box" data-aos="fade-up" data-aos-duration="750" data-aos-delay="150">
                    <img
                        src={require("../../../../assets/img/dentist/home/step_auto_icon.webp")}
                        alt="step2"
                        className="step-image"
                    />
                    <p className="text-step-index">Step 2</p>
                    <p className="text-step-name">Adaptive AI Designs Like You</p>
                    <p className="text-step-description">
                        Your AI adapts to your style for consistent, precise designs.
                    </p>
                </div>
                <div className="step-box" data-aos="fade-up" data-aos-duration="750" data-aos-delay="300">
                    <img
                        src={require("../../../../assets/img/dentist/home/step_check_icon.webp")}
                        alt="step3"
                        className="step-image"
                    />
                    <p className="text-step-index">Step 3</p>
                    <p className="text-step-name">Batch Review & Approve</p>
                    <p className="text-step-description">Quickly review, adjust, and send cases to production.</p>
                </div>
            </div>
        </div>
    );
}
