import React, { useState, useEffect } from "react";
import { Modal, Button } from "antd";
import "./IDSPopup.scss";

const IDSPopup = () => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        // Check if the user has closed the popup in the current session
        const hasClosedPopup = sessionStorage.getItem("idsPopupClosed");

        // If the popup has not been closed in the current session, show it
        if (!hasClosedPopup) {
            setShow(true);
        }
    }, []);

    const handleClose = () => {
        setShow(false);
        // Set a flag in sessionStorage to indicate that the user has closed the popup in the current session
        sessionStorage.setItem("idsPopupClosed", "true");
    };

    const handleBookAppointment = () => {
        window.open("https://preteethai2.pipedrive.com/scheduler/kO2OG7ur/ids-cologne-calendar", "_blank");
        handleClose();
    };

    return (
        <Modal
            open={show}
            onCancel={handleClose}
            centered
            className="ids-popup-modal"
            footer={null}
            closable={false}
            width={"fit-content"}
            maskClosable={true}
            keyboard={true}
            focusTriggerAfterClose={false}
            autoFocus={false}
        >
            <img src={require("../../assets/img/dentist/home/img_ids_2025.jpg")} alt="IDS 2025" />
            <h3>Meet Us at IDS 2025 – Book Your Spot!</h3>
            <p>
                Visit us at IDS 2025 (Hall 4.1, Booth B017) and see Dentscape's AI in action. Book a session with our
                team now!
            </p>

            <div className="ids-popup-modal-footer">
                <Button className="btn-book-appointment" key="book" onClick={handleBookAppointment}>
                    Book an Appointment
                </Button>
                <Button className="btn-maybe-later" key="maybe-later" onClick={handleClose}>
                    Maybe later
                </Button>
            </div>
        </Modal>
    );
};

export default IDSPopup;
