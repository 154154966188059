import "./Features.scss";

export default function Features() {
    return (
        <div className="features">
            <h1 className="features-title">Explore Our AI-Driven Dental Solutions</h1>

            <div className="features-container">
                <div className="features-card" data-aos="fade-up" data-aos-duration="750">
                    <h3>100 Crowns in 10 Minutes</h3>
                    <p>Design at lightning speed with AI-driven precision.</p>
                </div>

                <div className="features-card" data-aos="fade-up" data-aos-duration="750" data-aos-delay="150">
                    <h3>From Prescription to Production</h3>
                    <p>Seamlessly convert prescriptions into ready-to-produce designs.</p>
                </div>

                <div className="features-card" data-aos="fade-up" data-aos-duration="750" data-aos-delay="300">
                    <h3>Cloud-Based & Adaptive</h3>
                    <p>Access anywhere—AI learns and refines your style.</p>
                </div>
            </div>
        </div>
    );
}
